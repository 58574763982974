<template>
  <v-navigation-drawer app v-model="drawerState">
    <v-list dense nav class="pt-2 pb-16 px-0">
      <v-list-item link :to="'/'">
        <v-list-item-content class="ps-2">
          <v-list-item-title>홈</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <template v-for="(item, index) in topics">
        <v-divider :key="-index" />
        <v-list-item link :key="item.id" :to="`/topics/${item.id}`">
          <v-list-item-content class="ps-2">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link v-for="subitem in item.items" :key="subitem.id" :to="`/topics/${subitem.id}`">
          <v-list-item-content class="py-0 ps-10">
            <v-list-item-title>{{ subitem.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  name: 'NavigationDrawer',
  computed: {
    ...mapState ({
      drawer: state => state.app.drawer,
      topics: state => state.app.topics,
    }),
    drawerState: {
      get() {
        console.log('drawerState.get: drawer=', this.drawer);

        return this.drawer;
      },
      set(value) {
        console.log('drawerState.set: value=', value);

        this.setDrawer(value);
      },
    },
  },
  created() {
    console.log('NavigationDrawer.created: topics=', (this.topics) ? this.topics.length : -1);

    this.fetchTopics();
  },
  methods: {
    ...mapActions('app', [
      'getTopics',
    ]),
    ...mapMutations('app', [
      'setDrawer',
    ]),
    fetchTopics() {
      console.log('fetchTopics: topics=', (this.topics) ? this.topics.length : -1);

      if (this.topics) {
        return;
      }

      this.getTopics();
    },
  },
}
</script>
